<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex flex-row align-center justify-space-between mb-4">
      <div class="top-header mb-0 text-uppercase">
        <v-icon class="cursor--pointer" @click="backleads">mdi-reply</v-icon>

        {{ "Leads" }}
      </div>
    </div>
    <v-card color="white" elevation="1">
      <v-card-title class="primary white--text" primary-title>
        <span>Google Sheet List</span>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="white"
              class="mx-2"
              v-on="on"
              small
              icon
              @click="newGooglesheet"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>New GoogleSheet</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn color="white" v-on="on" small icon @click="getGoogleSheets">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Reload List</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="googleSheets"
        :loading="loading"
        :items-per-page="5"
        item-key="uuid"
        class="elevation-1"
        :options.sync="options"
        :server-items-length="totalsheets"
        :footer-props="footerProps"
        single-expand
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="info"
                v-on="on"
                icon
                v-if="item"
                @click="toEdit(item)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
            </template>
            <span>Edit Google Sheet</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                color="error"
                v-on="on"
                icon
                v-if="item"
                @click="toDelete(item)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
            <span>Delete Google Sheet</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <template v-if="item.active">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon color="success" v-on="on" @click="confirmActive(item)"
                  >mdi-check</v-icon
                >
              </template>
              <span>Click to deactivate</span>
            </v-tooltip>
          </template>
          <template v-else>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on" @click="confirmActive(item)"
                  >mdi-close</v-icon
                >
              </template>
              <span>Click to active</span>
            </v-tooltip>
          </template>
        </template>
        <template v-slot:[`item.createAt`]="{ item }">
          {{ formatDate(item.createAt) }}
        </template>
        <template v-slot:[`item.howKnow`]="{ item }">
          <span>{{ item.howKnow ? item.howKnow : "-" }}</span>
        </template>
        <template v-slot:[`item.everyInminutes`]="{ item }">
          {{ item.everyInminutes + " minutes" }}
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container>
              <v-row>
                <v-col cols="12" sm="3">
                  <strong>Active: </strong>
                  <strong
                    :class="item.active ? 'success--text' : 'error--text'"
                    >{{ item.active ? "Active" : "Deactivate" }}</strong
                  >
                </v-col>
                <v-col cols="12" sm="3">
                  <strong>Name:</strong> <span>{{ item.name }}</span>
                </v-col>
                <v-col cols="12" sm="3">
                  <strong>Sheet name:</strong> <span>{{ item.sheetName }}</span>
                </v-col>
                <v-col cols="12" sm="3">
                  <strong>Source: </strong>
                  <span>{{ item.howKnow }}</span>
                </v-col>

                <v-col cols="12" sm="3">
                  <strong>Update interval: </strong>
                  <span>{{ item.everyInminutes + " minutes" }}</span>
                </v-col>
                <v-col cols="12" sm="3">
                  <strong>Last Row:</strong> <span>{{ item.lastRow }}</span>
                </v-col>

                <v-col cols="12" sm="3">
                  <strong>Created at: </strong>
                  <span>{{ formatDateH(item.createAt) }}</span>
                </v-col>
                <v-col cols="12" sm="3">
                  <strong>Google id:</strong> <span>{{ item.googleId }}</span>
                </v-col>
              </v-row>
              <v-divider class="my-2"></v-divider>
              <template v-if="item.matchingSetting">
                <div class="d-flex justify-center">
                  <strong>Matching Settings</strong>
                </div>

                <v-divider class="my-2"></v-divider>
                <v-row>
                  <template v-if="item.matchingSetting.name">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>name: </strong
                      >{{ item.matchingSetting.name }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.last_name">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>last_name: </strong
                      >{{ item.matchingSetting.last_name }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.phone">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>phone: </strong
                      >{{ item.matchingSetting.phone }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.email">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>email: </strong
                      >{{ item.matchingSetting.email }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.created_time">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>created_time: </strong
                      >{{ item.matchingSetting.created_time }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.campaing">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>campaing: </strong
                      >{{ item.matchingSetting.campaing }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.status">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>status: </strong
                      >{{ item.matchingSetting.status }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.weight">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>weight: </strong
                      >{{ item.matchingSetting.weight }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.height">
                    <v-col cols="12" sm="3" class="text-left">
                      <strong>height:</strong> {{ item.matchingSetting.height }}
                    </v-col>
                    {{}}
                  </template>
                  <template v-if="item.matchingSetting.birthDate">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>birthDate: </strong
                      >{{ item.matchingSetting.birthDate }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.zipCode">
                    <v-col cols="12" sm="3" class="text-left"
                      ><strong>zipCode: </strong
                      >{{ item.matchingSetting.zipCode }}</v-col
                    >
                  </template>
                  <template v-if="item.matchingSetting.procedureInterest">
                    <v-col cols="12" sm="4" class="text-left"
                      ><strong>procedureInterest: </strong
                      >{{ item.matchingSetting.procedureInterest }}</v-col
                    >
                  </template>
                </v-row>
              </template>
            </v-container>
          </td>
        </template>
      </v-data-table>

      <v-dialog
        v-model="dialogDelete"
        persistent
        :overlay="false"
        max-width="280"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="error white--text" primary-title>
            Delete Google Sheet
          </v-card-title>
          <v-card-text class="my-3 text-center"> Are you sure ? </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="cancelDelete">Cancel</v-btn>
            <v-btn
              color="error"
              :loading="loading"
              :disabled="loading"
              @click="confirmDelete"
              text
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import moment from "moment";
import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";

export default {
  name: "google-sheet-list",
  data() {
    return {
      expanded: [],
      dialogDelete: false,
      itemDelete: null,

      loading: false,
      footerProps: { "items-per-page-options": [15, 50, 100, 500] },
      options: {},
      totalsheets: 0,
      googleSheets: [],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Google Id",
          align: "start",
          sortable: false,
          value: "googleId",
        },
        {
          text: "Sheet name",
          align: "start",
          sortable: false,
          value: "sheetName",
        },
        {
          text: "Source",
          align: "start",
          sortable: false,
          value: "howKnow",
        },
        {
          text: "Update interval",
          align: "start",
          sortable: false,
          value: "everyInminutes",
        },
        {
          text: "Created At",
          align: "start",
          sortable: false,
          value: "createAt",
        },
        {
          text: "Active",
          align: "center",
          sortable: false,
          value: "active",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  watch: {
    options(val) {
      if (val != {}) {
        this.getGoogleSheets();
      }
    },
  },

  methods: {
    backleads() {
      this.$router.push("/leads_center");
    },
    newGooglesheet() {
      this.$router.push(`/leads_center/google_sheet/create`);
    },

    toDelete(item) {
      this.dialogDelete = true;
      this.itemDelete = item;
    },

    cancelDelete() {
      this.dialogDelete = false;
      this.itemDelete = null;
    },
    confirmDelete() {
      this.loading = true;
      getAPI
        .delete("/googleapi/deleteTask/" + this.itemDelete.uuid)
        .then(() => {
          notifyInfo("Task has been deleted");
          this.cancelDelete();
          this.getGoogleSheets();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    toEdit(item) {
      localStorage.setItem("googleItem", JSON.stringify(item));
      this.$router.push(`/leads_center/google_sheet/edit`);
    },

    confirmActive(item) {
      this.loading = true;
      getAPI
        .put("/googleapi/updateTask/" + item.uuid, { active: !item.active })
        .then(() => {
          if (item.active) {
            notifyInfo("Task has been deactivated");
          } else {
            notifySuccess("Task has been activated");
          }
          this.loading = false;
          this.getGoogleSheets();
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    getGoogleSheets() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const itmper = itemsPerPage != undefined ? itemsPerPage : 15;
      const p = page != undefined ? page : 1;
      const reqrange = {
        limit: itmper,
        offset: (p - 1) * itmper,
      };
      getAPI
        .post("/googleapi/getTasks", reqrange)
        .then((res) => {
          this.googleSheets = res.data.result;
          this.totalsheets = res.data.count;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },
    formatDateH(date) {
      const d = moment(date).format("MM/DD/YYYY, hh:mm a");
      return d;
    },
  },
};
</script>
<style lang="scss" scoped></style>
